import React from 'react'
import { Link, graphql } from 'gatsby'
import get from 'lodash/get'
import Layout from '../components/layout'
import RichText from '../components/rich-text'
import gsap from 'gsap'

class About extends React.Component {
  constructor (props) {
    super(props);
    this.aboutImage = React.createRef();
    this.index = 0;
  }
  onLinkEnter (e) {
    if (window.innerWidth < 900) return;
    
    const index = e.currentTarget.getAttribute('data-index');
    const aboutImage = this.aboutImage.current.querySelector(`[data-index="${index}"]`);
    gsap.set(aboutImage, {
      opacity: 1,
      zIndex: this.index,
    });
    this.index++;
  }

  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title');
    const about = get(this, 'props.data.allContentfulAbout.nodes[0]');
    const experiences = get(this, 'props.data.allContentfulExperience.edges');
    const imgsData = [
      {
        opacity: 0,
        width: '70%',
        left: '20%',
        top: '13%'
      }, {
        opacity: 0,
        width: '60%',
        left: '7%',
        top: '30%'
      }, {
        opacity: 0,
        width: '75%',
        left: '25%',
        top: '0%'
      }, {
        opacity: 0,
        width: '60%',
        left: '2%',
        top: '20%'
      }, {
        opacity: 0,
        width: '52%',
        left: '41%',
        top: '36%'
      }
    ];

    return (
      <Layout classes={['o-beige']} title="About Lotte Bijlsma">
        <main className="l-wrapper">
          <h1 className="u-visually-hidden">About Lotte Bijlsma</h1>
          <div className="g-about">
            <div className="g-about__content">
              <div className="g-about__introduction o-rich-text o-title-large">
                {about.introductionText.introductionText}
              </div>
              <section className="g-about__socials">
                <div className="g-about__socials-category">
                  <h2 className="o-title-small">Socials</h2>
                  <RichText classes={['g-about__socials-text']} content={about.socials.childMarkdownRemark.html.replace(/\n/g, "<br>")} />
                </div>
                <div className="g-about__socials-category">
                  <h2 className="o-title-small">Experience</h2>
                  <div className="g-about__socials-text o-rich-text">
                    {experiences.map(({node = node.node}, index) => {
                      return (
                        <p key={node.name}>
                          <a href={node.website} data-index={index} onMouseEnter={e => this.onLinkEnter(e)}>{node.name}</a>
                        </p>
                      )
                    })}
                  </div>
                </div>
              </section>
            </div>
            <div className="g-about__image" ref={this.aboutImage}>
              <img src={about.media[0].file.url} />
              {experiences.map(({node = node.node}, index) => {
                return (
                  <img key={node.name} data-index={index} src={node.thumbnail.file.url + "?fit=pad&w=800"} className="g-about__image-experience" style={imgsData[index % imgsData.length]} />
                )
              })}
            </div>
          </div>
        </main>
      </Layout>
    )
  }
}

export default About

export const pageQuery = graphql`
  query AboutQuery {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulExperience {
      edges {
        node {
          id
          name
          website
          thumbnail {
            file {
              url
            }
          }
        }
      }
    }
    allContentfulAbout {
      nodes {
        id
        introductionText {
          introductionText
        }
        socials {
          childMarkdownRemark {
            html
          }
        }
        experience {
          childMarkdownRemark {
            html
          }
        }
        media {
          file {
            url
          }
        }
      }
    }
  }
`
